@font-face {
  font-family: 'OperatorMonoSSmLig Nerd Font';
  src:
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Italic.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Italic.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OperatorMonoSSmLig Nerd Font';
  src:
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Book.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Book.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Operator Mono';
  src:
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Italic.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Italic.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Operator Mono';
  src:
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Book.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/gh/Innei/zshrc@0.1.0/webfont/OperatorMonoSSmLigNerdFontAAEOPSLMDI-Book.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'),
    url('https://fonts.googleapis.com/css2?family=Fira+Code:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
  font-weight: normal;
  font-style: normal;
}
