html {
  --font-sans: theme('fontFamily.sans');

  /* Accent color for form controls */
  accent-color: var(--accent-color);

  /* make app like native app in mobile */
  -webkit-tap-highlight-color: transparent;

  font-family: var(--font-sans);
}

a,
details summary {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root {
  --bg-opacity: rgba(255, 255, 255, 0.72);
}

[data-theme='dark'] {
  --bg-opacity: rgba(29, 29, 31, 0.72);
}

::selection {
  background-color: var(--accent-color);
  color: theme(colors.white) !important;
  text-shadow: none;
}

[data-theme='dark'] ::selection {
  background-color: var(--accent-color);
}

/* 视图过渡组 */
::view-transition-group(root) {
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

/* 新视图的过渡效果 */
::view-transition-new(root) {
  animation-name: reveal-light;
}

/* 旧视图的过渡效果 */
::view-transition-old(root),
.dark::view-transition-old(root) {
  animation: none;
  z-index: -1;
}

/* 暗模式下的新视图过渡效果 */
.dark::view-transition-new(root) {
  animation-name: reveal-dark;
}

@keyframes reveal-light {
  from {
    clip-path: polygon(50% -71%, -50% 71%, -50% 71%, 50% -71%);
  }
  to {
    clip-path: polygon(50% -71%, -50% 71%, 50% 171%, 171% 50%);
  }
}

@keyframes reveal-dark {
  from {
    clip-path: polygon(171% 50%, 50% 171%, 50% 171%, 171% 50%);
  }
  to {
    clip-path: polygon(171% 50%, 50% 171%, -50% 71%, 50% -71%);
  }
}

@keyframes turnOff {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
  }
}
