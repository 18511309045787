body::-webkit-scrollbar {
  height: 0;
}

html {
  /*  for firefox */
  scrollbar-color: theme(colors.zinc.100);
  scrollbar-width: thin;
}

html.dark {
  scrollbar-color: theme(colors.zinc.800);
}

body {
  overflow: overlay;
  /* scrollbar-gutter: stable; */
}

[data-theme='dark'] {
  *::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb:hover {
    border: 3px solid theme(colors.zinc.700/20);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: theme(colors.zinc.800/96);
  }

  *::-webkit-scrollbar {
    background: theme(colors.zinc.800);
  }
}

*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
  border: 3px solid theme(colors.zinc.300/40);
  border-radius: 5px;
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background: theme(colors.zinc.100);
}

*::-webkit-scrollbar-thumb {
  background: theme(colors.neutral);
}

*::-webkit-scrollbar-thumb:hover {
  background: theme(colors.neutral/80);
}

*::-webkit-scrollbar-corner {
  background: theme(colors.zinc.100);
}
